<template>
  <v-card flat :class="color || 'grey lighten-2'">
    <v-card-title :class="collapse ? '' : 'pb-2'" v-if="title">
      <span v-if="title">{{ title }}</span>
      <slot name="title"></slot>
    </v-card-title>
    <v-card-text v-if="!collapse" class="pt-0 pr-2 pb-2 pl-2">
      <v-data-table
        dense disable-sort
        :headers="headers"
        :items="items"
        :no-data-text="title ? 'No ' + title : 'No Data'"
        :hide-default-header="rows.length == 0"
        :hide-default-footer="rows.length == 0 || itemsPerPage != Infinity"
        :items-per-page="itemsPerPage || Infinity"
      >
        <template v-slot:item="{ item, headers }">
          <tr :class="formatRow(item)" @click="handleClick(item)">
            <td
              v-for="header in headers"
              :key="header.value"
              class="pa-0"
            >
              <div :class="formatCell(header, item)">{{ formatVal(item[header.value], header.type) }}</div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>



<script>
export default {
  props: {
    title: String,
    color: String,
    heads: Array,
    rows: Array,
    collapse: Boolean,
    itemsPerPage: Number,
    gradients: Object,
    currencyFormat: String,
    onSelect: Function,
  },
  data() {
    return {
      selected: [],
    }
  },
  computed: {
    headers () {
      return this.heads.map((head, i) => {
        if(i == 0)
          return { text:head, value:head };
        else
          return { text:head, value:head, align:'end', type:this.currencyFormat || 'currencyInr2d' };
      });
    },
    items () {
      let items = [];
      for(let i = 0; i < this.rows.length; i++) {
        items.push({});
        for(let j = 0; j < this.rows[i].length; j++)
          items[i][this.heads[j]] = this.rows[i][j];
      }
      return items;
    },
    fromatFns () {
      if(!this.gradients)
        return {};
      let fromatFns = {};
      let minMaxs = {};
      Object.keys(this.gradients).forEach(key => {
        if(this.heads.indexOf(key) == -1)
          return;
        fromatFns[key] = [];
        if(!this.gradients[key].ranges)
          return;
        this.gradients[key].ranges.forEach(head => {
          if(this.heads.indexOf(head) == -1)
            return;
          fromatFns[key].push(head);
          minMaxs[head] = { min: Infinity, max: -Infinity };
        });
      });
      this.items.forEach(item => {
        if(Object.values(item)[0] == 'Total')
          return;
        Object.keys(minMaxs).forEach(head => {
          if(item[head] == null)
            return;
          minMaxs[head].min = Math.min(minMaxs[head].min, item[head]);
          minMaxs[head].max = Math.max(minMaxs[head].max, item[head]);
        });
      });
      Object.keys(fromatFns).forEach(key => {
        if(this.gradients[key].minMax) {
          let { min, max } = this.gradients[key].minMax;
          fromatFns[key] = (val) => this.gradients[key].cssClassFn(val, min, max);
        } else {
          let { min, max } = { min: Infinity, max: -Infinity };
          fromatFns[key].forEach(head => {
            min = Math.min(minMaxs[head].min, min);
            max = Math.max(minMaxs[head].max, max);
          });
          fromatFns[key] = (val) => this.gradients[key].cssClassFn(val, min, max);
        }
      });
      return fromatFns;
    }
  },
  methods: {
    formatVal (val, type) {

      return window.utils.format(val, type);

    },
    formatCell (header, item) {

      let cssClass = `text-no-wrap px-4 py-2 text-${ header.align }`;

      if(item[this.heads[0]] == 'Total') {
        // Do nothing
      } else if(this.fromatFns[header.value]) {
        cssClass += ' ' + this.fromatFns[header.value](item[header.value]);
      }

      return cssClass;

    },
    formatRow(item) {

      let val = item[this.heads[0]];

      if(val == 'Total')
        return (this.color || 'grey lighten-2') + ' font-weight-medium';

      if(this.selected.indexOf(val) != -1)
        return 'grey lighten-4';

      return '';

    },
    handleClick (item) {

      let val = item[this.heads[0]];

      if(this.selected.length == 0) {

        this.selected.push(val);

      } else if(this.selected.length == 1 && this.selected[0] == val) {

        this.selected.pop();

      } else {

        if(event.metaKey || event.altKey || event.shiftKey) {
          let idx = this.selected.indexOf(val);
          if(idx == -1)
            this.selected.push(val);
          else
            this.selected.splice(idx, 1);
        } else {
          this.selected = [ val ];
        }

      }

      if(this.onSelect)
        this.onSelect(this.selected);

    },
  },
};
</script>
