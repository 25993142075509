<template>
  <div>

    <TableV2 title="Accounts" :heads="heads" :rows="rows" :gradients="gradients" :onSelect="rowClickFn"/>

    <Table v-if="accounts.length" :title="arrToStr(accounts)" api="/api/accounts/summary" :params="apiParams" :rowClassFn="rowClassFn" class="mt-2 blue lighten-4">
      <template v-slot:title>
        <v-btn plain fab x-small style="text-transform: unset;" @click="accounts = ['Startups','StockMarket','ParkedFunds','FixedReturns']"></v-btn>
      </template>
    </Table>

    <Table v-if="accounts.length" title="Credits & Debits" api="/api/heads/yoy" :params="apiParamsCre" :rowClassFn="rowClassFn" class="mt-2 green lighten-5">
      <template v-slot:title>
        <v-btn-toggle dense mandatory v-model="groupByCre" >
          <v-btn v-if="accounts.length > 1" plain style="text-transform: unset;" value="Account">Accounts</v-btn>
          <v-btn plain style="text-transform: unset;" value="Head">Heads</v-btn>
          <v-btn plain style="text-transform: unset;" value="Tag">Tags</v-btn>
        </v-btn-toggle>
      </template>
    </Table>

    <Table v-if="accounts.length" title="Incomes" api="/api/heads/yoy" :params="apiParamsInc" :rowClassFn="rowClassFn" class="mt-2 green lighten-4">
      <template v-slot:title>
        <v-btn-toggle dense mandatory v-model="groupByInc" >
          <v-btn v-if="accounts.length > 1" plain style="text-transform: unset;" value="Account">Accounts</v-btn>
          <v-btn plain style="text-transform: unset;" value="Head">Heads</v-btn>
          <v-btn plain style="text-transform: unset;" value="Tag">Tags</v-btn>
        </v-btn-toggle>
      </template>
    </Table>

    <Table v-if="accounts.length" title="Expenses" api="/api/heads/yoy" :params="apiParamsExp" :rowClassFn="rowClassFn" class="mt-2 red lighten-5">
      <template v-slot:title>
        <v-btn-toggle dense mandatory v-model="groupByExp" >
          <v-btn v-if="accounts.length > 1" plain style="text-transform: unset;" value="Account">Accounts</v-btn>
          <v-btn plain style="text-transform: unset;" value="Head">Heads</v-btn>
          <v-btn plain style="text-transform: unset;" value="Tag">Tags</v-btn>
        </v-btn-toggle>
      </template>
    </Table>

    <Table v-if="accounts.length" title="Taxes" api="/api/heads/yoy" :params="apiParamsTax" :rowClassFn="rowClassFn" class="mt-2 red lighten-4">
      <template v-slot:title>
        <v-btn-toggle dense mandatory v-model="groupByTax" >
          <v-btn v-if="accounts.length > 1" plain style="text-transform: unset;" value="Account">Accounts</v-btn>
          <v-btn plain style="text-transform: unset;" value="Head">Heads</v-btn>
          <v-btn plain style="text-transform: unset;" value="Tag">Tags</v-btn>
        </v-btn-toggle>
      </template>
    </Table>

  </div>
</template>

<script>
import Table from "../components/Table";
import TableV2 from "../components/TableV2";
export default {
  components: {
    Table,
    TableV2
  },
  props: {
    doGet: Function,
  },
  data() {
    return {

      heads: [],
      rows : [],

      accounts: [],

      groupByCre: 'Tag',
      groupByInc: 'Tag',
      groupByExp: 'Tag',
      groupByTax: 'Head',

      apiParams: { accounts: [] },
      apiParamsCre: { type: 'CreditsDebits', accounts: [], groupBy: 'Tag'  },
      apiParamsInc: { type: 'Incomes',       accounts: [], groupBy: 'Tag'  },
      apiParamsExp: { type: 'Expenses',      accounts: [], groupBy: 'Tag'  },
      apiParamsTax: { type: 'Taxes',         accounts: [], groupBy: 'Head' },

      timer: undefined,

    }
  },
  mounted() {
    this.fetchData();
    this.timer = process.env.NODE_ENV == 'development'
        ? setInterval(this.fetchData, 5 * 1000)
        : setInterval(this.fetchData, 60 * 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  watch: {
    accounts() {
      this.apiParams.accounts    = this.accounts;
      this.apiParamsCre.accounts = this.accounts;
      this.apiParamsInc.accounts = this.accounts;
      this.apiParamsExp.accounts = this.accounts;
      this.apiParamsTax.accounts = this.accounts;
    },
    groupByCre() {
      this.apiParamsCre.groupBy = this.groupByCre;
    },
    groupByInc() {
      this.apiParamsInc.groupBy = this.groupByInc;
    },
    groupByExp() {
      this.apiParamsExp.groupBy = this.groupByExp;
    },
    groupByTax() {
      this.apiParamsTax.groupBy = this.groupByTax;
    },
  },
  computed: {
    gradients() {
      let heads = this.heads.slice(1);
      return heads.reduce((gradients, head) => {
        gradients[head] = {
          ranges: heads,
          cssClassFn: window.utils.cssRedGreenGradient
        };
        return gradients;
      }, {});
    },
  },
  methods: {
    async fetchData () {

      let data = await this.doGet('/api/accounts?profileId=%23Prashant');

      this.heads = data[0];
      this.rows  = data.slice(1);

    },
    arrToStr(arr) {
      if(arr.length == 1)
        return arr[0];
      let str = '';
      arr.forEach(val => str = str + ' + ' + val);
      return str.substring(2);
    },
    rowClassFn (row) {
      if(row.Name == 'Total' || row.Type == 'Balance' || row.Account == 'Total' || row.Head == 'Total' || row.Tag == 'Total')
        return 'font-weight-bold';
      return '';
    },
    rowClickFn (vals) {
      this.accounts = vals.filter(val => val != 'Total');
    },
  }
};
</script>
